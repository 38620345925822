import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '../../../../utility/string.utility';
export default {
  name: 'fmsMeterReadingElectricityUpload',
  components: {
    DatePicker,
    
  },
  data() {
    return {
      totalRows: null,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showExcelUploadModal: false,
      // unitType:{
      //   text:null,
      //   value:null
      // },
      // unitName: {
      //   value:null,
      //   text:null
      // },
      meterNumber: null,
      projectCode:{
        text:null,
        value:null
      },
      previousReadingDate:null,
      currentReadingDate:null,
      status: {
        value: null,
        text: null
    },
      readingType:{
        value:null,
        text:null
      },
      fileName:null,
      showValueSetModal: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      meterReadingData: [],
      meterReadingFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
      },
        {
          key:'status',
          label:'Status'
         }, 
         {
           key:'status_msg',
           label:'Status Message'
 
         },
        {
          key: 'billing_status',
          label: 'Biling Status'
        },
        {
          key:'reading_type',
          label:'Reading Type'
        },
        {
          key:'previous_reading',
          label:'Previous Reading'
        },
        {
          key: 'current_reading',
          label: 'Current Reading'
        },
        {
          key:'previous_reading_date',
          label:'Previous Reading Date'
        },
        {
          key: 'current_reading_date',
          label: 'Current Reading Date'
        },
        {
          key: 'customer_name',
          label: 'Customer Name'
        },
        {
          key: 'final_bill_doc_no',
          label: 'final_bill_doc_no'
        },
        {
          key:'meter_no',
          label:'Meter Number'
        },
        
        {
          key:'prj_code',
          label:'Project Code'
        },
        {
          key:'prj_name',
          label:'Project Name'
        }, 
        
        {
          key:'unit_name',
          label:'Unit Name'
        }, 
        {
          key:'unit_type',
          label:'Unit Type'
        },  
        {
          key:'file_name',
          label:'File Name'
        }
      ],
      requestStatus:null,
      requestId:null,
      templateId:null,
      requestNumId:null,
      selectAllCheckBox: false,
      reading_id:null
    };
  },
  watch: {
    currentPage: function() {
      this.getFmsMeterReadingUpload();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsMeterReadingUpload();
    },
    selectAllCheckBox: function () {
        this.checkUncheckAll();
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
        if (actionName === 'delete') {
            this.deleteMeterUpload();
        }
     
        
      }
    });
  },
  methods: {
    checkUncheckAll() {
      this.meterReadingData = this.meterReadingData.map((data) => {
          data.select = data.status_flag !== 'PROCESSED' && this.selectAllCheckBox;
          return data;
      });
  },
    getFmsMeterReadingUpload() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        // customer_name: this.customerName,
        // unit_type: this.unitType,
        // unit_name: this.unitName,
        meter_no: this.meterNumber,
        prj_code :this.projectCode.value,
        pre_reading_date:this.previousReadingDate,
        curr_reading_date :this.currentReadingDate,
        status:this.status.value,
        reading_type :this.readingType.value,
        file_name :this.fileName
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getFmsMeterUploadExcel', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
          this.meterReadingData = response.data.data.page
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTemplateDetails(item) {
      const payload = {
          _page: 0,
          _limit: 10,
          template_name: 'FMS_METER_LOADER_TMP'
      };
      this.loader = true;
      this.$store
          .dispatch('assets/getTemplateDetails', payload)
          .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                  this.requestId = resp.data.data.page[0].request_id;
                  this.templateId = resp.data.data.page[0].template_id;
                  this.saveSubmitRequest(item);
              }
          })
          .catch(() => {
              this.loader = false;
          });
  },
  saveSubmitRequest(item) {
    const payload = {
        admin_submit_req_details: [
            {
                request_num: 0,
                request_id: this.requestId,
                template_id: this.templateId,
                schedule_type: 'asap', //pass hardcode
                request_start_date: null,
                request_end_date: null,
                resubmit_interval: null,
                resubmit_interval_unit: null,
                day_of_month: null,
                day_of_week: null,
                output_format_id: 'SBLANK', //pass hardcode
                no_of_args: 25,
                request_parameter: null,
                sms_flag: false,
                email_flag: false,
                whatsapp_flag: false,
                child_req_count: null,
                email_comm_template_id: null,
                sms_comm_template_id: null,
                whatsapp_comm_template_id: null,
                argument1: null,
                argument2: null,
                argument3: null,
                argument4: null,
                argument5: null,
                argument6: null,
                argument7: null,
                argument8: null,
                argument9: null,
                argument10: null,
                argument11: null,
                argument12: null,
                argument13: null,
                argument14: null,
                argument15: null,
                argument16: null,
                argument17: null,
                argument18: null,
                argument19: null,
                argument20: null,
                argument21: null,
                argument22: null,
                argument23: null,
                argument24: null,
                argument25: null
            }
        ]
    };
    this.loader = true;
    this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
            this.loader = false;
            if (resp.status === 201) {
              this.requestNumId = resp.data.data[0].id;
                this.getSubmitRequest(item);
            }
        })
        .catch(() => {
            this.loader = false;
        });
},
getSubmitRequest() {
  this.loader = true;
  this.$store
      .dispatch('template/getSubmitRequest', this.requestNumId)
      .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
          this.requestStatus = resp.data.data[0].status;
          this.reqId = resp.data.data[0].id;
          }
      })
      .catch(() => {
          this.loader = false;
      });
},
clear(){
  this.projectCode={
    text:null,
    value:null

  },
  this. meterNumber= null,
  this.previousReadingDate =null,
  this.currentReadingDate=null,
  this.status= {
    value: null,
    text: null
},
this.fileName=null,
 this.readingType={
  value:null,
  text:null
}
  
},
openValueSetModal(vsetCode) {
  this.vsetCode = vsetCode;
  this.parent_value_set_id = null;
  this.showValueSetModal = true;
 this.setTimeVsetCode = setTimeout(() => {
    const vSetData = {
      valueSetName: vsetCode,
      multiFlag: null
    };
    this.eventBus.$emit('valueSetCode', vSetData);
  }, 0);
},
selectedvalueSet(item){
  // if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE){
  //   this.unitType.text =item.value_meaning,
  //   this.unitType.value=item.value_code;
  //  }
     if(this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG){
    this.status.text = item.value_meaning;
   this.status.value = item.value_code;
  } else if (this.vsetCode === appStrings.VALUESETTYPE.READING_TYPE){
    this.readingType.text=item.value_meaning;
    this.readingType.value=item.value_code;
  } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL){
    this.projectCode.text =item.fms_prj_name;
    this.projectCode.value =item.fms_prj_code;
  }
  // } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY_VSET){
  //   this.unitName.text =item.unit_display_name;
  //   this.unitName.value =item.fms_unit_id;
  // }
  // } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET){
  //   this.unitName.text=
  // }


},
closeValueSetModal() {
  this.showValueSetModal = false;
},
clearVsetValues(vsetCode){
  if (vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE){
    this.unitType.text =null;
    this.unitType.value =null;
  } else if ( vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG){
    this.status.text =null;
    this.status.value =null;
  } else if ( vsetCode === appStrings.VALUESETTYPE.READING_TYPE){
    this.readingType.text =null;
    this.readingType.value =null;
  } else if (vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL){
    this.projectCode.text =null;
    this.projectCode.value =null;
  }

},
deleteMeterUpload() {
  const promt = confirm('Are you sure, you want to delete this Meter Upload?');
  if (promt) {
      const filterData = this.meterReadingData.filter(data => data.select);
      const MetertUpload = filterData.map(elem => {
          return {
            id: elem.reading_id
          };
      });
      const payload = { reading_id: MetertUpload }
      this.loader = true;
      this.$store
          .dispatch('masterData/deleteFmsMeterUploadExcel', payload)
          .then(response => {
              this.loader = false;
              if (response.status === 200) {
                  this.editMode = false;
                  this.showAlert = true;
                  this.isSuccess = true;
                  alert('Record Deleted Successfully');
                  this.getFmsMeterReadingUpload();
              } else {
                  this.showAlert = true;
                  this.isSuccess = false;
                  alert(response.response.data.message)
              }
          })
  }
},
// checkUnitorSubUnit(unitType) {
//   if (unitType === 'FMS_UNIT') {
//     this.openValueSetModal('FMS_UNIT_DISPLAY_VSET');
//   } else if (unitType === 'FMS_SUB_UNIT') {
//     this.openValueSetModal('FMS_SUB_UNIT_DISPLAY_VSET');
//   } else if (unitType === 'LEASE_UNIT') {
//     this.openValueSetModal('LEASE_UNIT_VSET');
//   } 
// }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
